import React from "react";
import Footer from "../../common/Footer.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import readXlsxFile from "read-excel-file";
import SweetAlert from "sweetalert-react";
import { connect } from "react-redux";
import Select from "react-select";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import "../../bulkData.css";
import "sweetalert/dist/sweetalert.css";
import {
  fetchInfrigementAllStatus,
  fetchInfrigementEcommerceStatus,
} from "./../../infrigementStatusCode";
import { withAlert } from "react-alert";
import "../../assets/css/data-upload.css";
import { toCapitalCase } from "../../common/utils.js";

const FormData = require("form-data");
const { keyToReasonCode, reasonCodeToKey } = require('../../common/ReasonKeys.js');

class DataUpload extends React.Component {
  constructor(props) {
    super(props);

    this.saveData = this.saveData.bind(this);

    this.state = {
      authorised: true,
      platform: "",
      showDataSavedAlert: false,
      selectedOption: null,
      showFilesUploadedAlert: false,
      uploadedFiles: {},
      excelData: [],
      companies: [],
      brands: [],
      reportingDate: "",
      company: "",
      brand: "",
      files: [],
      dataSaveError: {},
      filesUploading: false,
      urlDuplicates: 0,
      lidDuplicates: 0,
      domainKeys: [
        "sn",
        "image",
        "domain",
        "tld",
        "priority",
        "status",
        "registrar",
        "ipAddress",
        "hosting",
        "lastMofifiedDate",
        "expiryDate",
        "registrationDate",
        "note",
        "name",
        "org",
        "address",
        "state",
        "country",
        "emailAddress",
        "contactNumber",
        "brand"
      ],
      websiteKeys: [
        "sn",
        "image",
        "url",
        "domain",
        "dateAdded",
        "priority",
        "status",
        "brand"
      ],
      socialMediaKeys: [
        "sn",
        "image",
        "platform",
        "heading",
        "link",
        "dateAdded",
        "priority",
        "status",
        "PostDate",
        "LikesCount",
        "CommentsCount",
        "SharedCount",
        "PostDuration_yt",
        "Follower/SubscriberCount",
        "FollowingCount",
        "LastPostDate",
        "LastPostComments",
        "LastPostLikes",
        "LastPostShare",
        "LastPostCount",
        "Creation/JoiningDate",
        "PostCount/YouTube_VideoCount",
        "ViewCount",
        "Telegram_LinkCount",
        "brand"
      ],
      ecommerceKeys: [
        "sn",
        "image",
        "site",
        "dateAdded",
        "url",
        "title",
        "price",
        "reason",
        "reasonCode",
        "comparativeLink",  //add the Key for Comparative Link
        "comparativePrice", //add the Key for Comparative Price
        "priority",
        "status",
        "username",
        "storeId",
        "address",
        "city",
        "country",
        "Rating",
        "numberOfRatings",
        "numberOfReviews",
        "mrp",
        "sellerRating",
        "contactNumber",
        "pid",
        "lid",
        "discountPercentage",
        "flipkartAssured",
        "brand"
      ],
      appStoreKeys: [
        "sn",
        "image",
        "appName",
        "appStore",
        "dateAdded",
        "url",
        "developerName",
        "latestPublishDate",
        "numberOfDownload",
        "version",
        "status",
        "priority",
        "price",
      ],
      selectedKey: "",
    };
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.company !== this.state.company) {
  //     this.getBrands();
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.company !== this.state.company) {
      this.getBrands();
    }

    if (
      this.state.brand &&
      this.state.brand !== prevState.brand // Check if the brand has changed
    ) {
      let selectedBrand = this.state.brands.filter(
        (brand) => brand.id === this.state.brand
      );

      if (selectedBrand.length) {
        let platform = selectedBrand[0].platform;

        this.setState(() => ({
          availablePlatforms: platform,
        }));
      } else {
        this.setState(() => ({
          availablePlatforms: null,
        }));
      }
    }
  }

  componentDidMount() {
    this.changeInputEvent();
    this.getCompanies();
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800,  //JiraId :- LDOTR-3
        },
      })
      .then((res) => {
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  changeInputBoxValue(value, rowKey, domainKey) {
    let data = this.state.excelData;

    data[rowKey][domainKey] = value;

    this.setState({
      excelData: data,
    });
  }

  // changeInputEvent() {
  //   const input = document.getElementById("input");
  //   input.addEventListener("change", () => {
  //     let excelFile = null;

  //     for (let i = 0; i < input.files.length; i++) {
  //       let file = input.files[i];

  //       if (
  //         file.type ===
  //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //       ) {
  //         excelFile = file;
  //         break;
  //       }
  //     }

  //     if (excelFile === null) {
  //       alert("No excel file found.");
  //       input.value = null;
  //     }

  //     readXlsxFile(excelFile).then((rows) => {
  //       // `rows` is an array of rows
  //       // each row being an array of cells.
  //       let data = [];
  //       let formData = [];

  //       rows.map((item, key) => {
  //         if (key > 0) {
  //           let fromItem = {};

  //           item.map((itemValue, itemIndex) => {
  //             fromItem[this.state[this.state.selectedKey][itemIndex]] =
  //               itemValue;
  //           });

  //           formData.push(fromItem);
  //         }
  //       });

  //       this.setState({ excelData: formData });
  //       this.setState({ files: input.files });

  //       this.handleFileUpload();
  //     });
  //   });
  // }

  changeInputEvent() {
    const REASON_CODE_INDEX = 8; // Replace with the actual index of the "Reason Code" column

    const input = document.getElementById("input");
    input.addEventListener("change", () => {
      let excelFile = null;

      for (let i = 0; i < input.files.length; i++) {
        let file = input.files[i];

        if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          excelFile = file;
          break;
        }
      }

      if (excelFile === null) {
        alert("No excel file found.");
        input.value = null;
        return;
      }

      readXlsxFile(excelFile).then((rows) => {
        // `rows` is an array of rows, each row being an array of cells
        let formData = [];
        rows.forEach((item, rowIndex) => {
          if (rowIndex > 0) {
            let formItem = {};

            item.forEach((itemValue, itemIndex) => {
              if (itemIndex === REASON_CODE_INDEX && this.state.platform.toLocaleLowerCase() === 'e-commerce') {
                // Modify Reason Code field
                let modifiedValue = itemValue.toString();
                if (keyToReasonCode[modifiedValue]) {
                  modifiedValue = keyToReasonCode[modifiedValue]
                } else {
                  modifiedValue = "InvalidReasonCode"
                }

                formItem[this.state[this.state.selectedKey][itemIndex]] =
                  modifiedValue;
              } else {
                formItem[this.state[this.state.selectedKey][itemIndex]] =
                  itemValue;
              }
            });

            formData.push(formItem);
          }
        });
        this.setState({ excelData: formData, files: input.files });
        this.handleFileUpload();
      });
    });
  }

  async handleFileUpload() {
    let files = this.state.files;
    let formData = new FormData();
    let regex = /newscrapper/;
    for (let i = 0; i < files.length; i++) {
      if (!regex.test(files[i])) {
        formData.append("files", files[i]);
      }
    }

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    this.setState({ filesUploading: true });

    await window.axios
      .post("/upload-images-to-s3", formData, options)
      .then((res) => {
        let uploadedFiles = {};

        res.data.Data.map((file, fileIndex) => {
          let uploadedImageName = file.uploadedImage.replace(/\s+/g, ' ');
          uploadedFiles[uploadedImageName] = file;
        });

        this.setState({ uploadedFiles: uploadedFiles });

        let newPreparedExcelData = [];
        let matchedImages = [];
        this.state.excelData.map((data, dataIndex) => {
          let imageName = (data.image || '').replace(/\s+/g, ' '); //Add "" if imageName is blank
          let dataImageInfo = this.state.uploadedFiles[imageName];
          data.imageUrl = null;
          data.s3BucketName = null;
          data.s3FullImagePath = null;

          if (dataImageInfo) {
            data.imageUrl = dataImageInfo.Location;
            data.s3BucketName = dataImageInfo.Bucket;
            data.s3FullImagePath = dataImageInfo.Key;
            matchedImages.push(dataImageInfo);
          } else {
            data.imageUrl = data.image || ''; //Add "" if imageName is blank
            data.s3BucketName = 'newscrapper';
            data.s3FullImagePath = data.image?.split('/')[1] || ''; //Add "" if imageName is blank
          }

          newPreparedExcelData.push(data);
        });

        this.setState({
          excelData: newPreparedExcelData,
          filesUploading: false,
        });
      })
      .catch((error) => {
        this.setState({ filesUploading: false });
      });
  }

  async saveData() {
    const options = {
      headers: { "Content-Type": "application/json" },
    };

    this.setState(() => ({ dataSaveError: {}, urlDuplicates: 0, lidDuplicates: 0 }));

    let invalidReasonCode = false;

    // Modify the value of reasonCode property in each object of excelData array
    const modifiedExcelData = this.state.excelData.map((value) => {
      if (this.state.platform === "e-commerce") {
        if (reasonCodeToKey[value.reasonCode] || keyToReasonCode[value.reasonCode]) {
          if (keyToReasonCode[value.reasonCode]) {
            value.reasonCode = keyToReasonCode[value.reasonCode];
          }
        } else {
          // Display error message for invalid reason code
          invalidReasonCode = true;
        }
      } else {
        // do nothing
      }

      //If dateAdded column exists then take reporting date as dateAdded
      if (value.dateAdded !== undefined) {
        value.dateAdded = this.state.reportingDate;
      }
      return value;
    });

    if (invalidReasonCode) {
      this.props.alert.show("Invalid Reason Code", { type: "error" });
      return;
    }

    const filteredModifiedExcelData = modifiedExcelData.filter(
      (value) => value !== null
    );
    try {
      await window.axios.post(
        "/save-bulk-data",
        {
          data: filteredModifiedExcelData,
          company: this.state.company,
          brand: this.state.brand,
          reportingDate: this.state.reportingDate,
          platform: this.state.platform,
        },
        options
      );
      this.setState({
        showDataSavedAlert: true,
        excelData: [],
      });
      document.getElementById("input").value = null;
      this.props.alert.show("Data Saved Successfully");
    } catch (error) {
      const errors = error.response?.data?.errors || {};
      const { urlCount, lidCount, whiteLabelUrl, data } = errors;
      const message = toCapitalCase(error.response?.data?.message || "")


      this.setState({
        dataSaveError: errors,
        urlDuplicates: urlCount,
        lidDuplicates: lidCount
      });

      if (whiteLabelUrl > 0) {
        const message = this.state.platform === "e-commerce"
          ? data?.[0] || 'Something went wrong'
          : `${this.state.platform === 'domain' ? "Domain" : "Links"} found in whitelabel list`;
        this.props.alert.show(message, { type: "error" });
      } else if (urlCount > 0) {
        this.props.alert.show("Duplicate Urls", { type: "error" });
      }
      else {
        if (message.toLocaleLowerCase().includes('validation error')) {
          this.props.alert.show("Invalid Entries", { type: "error" });

        } else {
          this.props.alert.show(message, { type: "error" });

        }
      }
    }

  }

  changePlatformSelectBox(e) {
    let selectedKeyValue = "domainKeys";

    let key = e.target.value;

    if (key === "website") {
      selectedKeyValue = "websiteKeys";
    }
    if (key === "social-media") {
      selectedKeyValue = "socialMediaKeys";
    }
    if (key === "e-commerce") {
      selectedKeyValue = "ecommerceKeys";
    }
    if (key === "app-store") {
      selectedKeyValue = "appStoreKeys";
    }

    this.setState({
      platform: e.target.value,
      excelData: [],
      selectedKey: selectedKeyValue,
    });
  }

  renderUploadDataBtn() {
    if (this.state.filesUploading) {
      return <></>;
    } else if (this.state.urlDuplicates > 0 && this.state.lidDuplicates > 0) {
      return (
        <>
          <div className="col-md-3">
            <div className="form-group">
              <button
                onClick={(e) => this.saveData()}
                className="mt-4 btn btn-ldotr "
              >
                Upload Data
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mt-4 form-group" style={{ color: 'red' }}>
              {this.state.lidDuplicates} Duplicate LIDs and {this.state.urlDuplicates} Duplicate URLs present.
            </div>
          </div>
        </>
      );
    } else if (this.state.urlDuplicates > 0) {
      return (
        <>
          <div className="col-md-3">
            <div className="form-group">
              <button
                onClick={(e) => this.saveData()}
                className="mt-4 btn btn-ldotr "
              >
                Upload Data
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mt-4 form-group" style={{ color: 'red' }}>
              {this.state.urlDuplicates} Duplicate URLs present.
            </div>
          </div>
        </>
      );
    } else if (this.state.lidDuplicates > 0) {
      return (
        <>
          <div className="col-md-3">
            <div className="form-group">
              <button
                onClick={(e) => this.saveData()}
                className="mt-4 btn btn-ldotr "
              >
                Upload Data
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mt-4 form-group" style={{ color: 'red' }}>
              {this.state.lidDuplicates} Duplicate LIDs present.
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="col-md-3">
          <div className="form-group">
            <button
              onClick={(e) => this.saveData()}
              className="mt-4 btn btn-ldotr "
            >
              Upload Data
            </button>
          </div>
        </div>
      </>
    );
  }

  domainComponent() {
    if (this.state.platform === "domain") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              {this.listHeaderComponent("Domain")}
              <div style={{ paddingTop: "20px" }} className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>SN.</th>
                          <th>IMAGE</th>
                          <th>DOMAIN</th>
                          <th>TLD</th>
                          <th>PRIORITY</th>
                          <th>STATUS</th>
                          <th>REGISTRAR</th>
                          <th>IP ADDRESS</th>
                          <th>HOSTING</th>
                          <th>LAST MODIFICATION DATE</th>
                          <th>EXPIRY DATE</th>
                          <th>REGISTRATION DATE</th>
                          <th>NOTE</th>
                          <th>NAME</th>
                          <th>ORG</th>
                          <th>ADDRESS</th>
                          <th>STATE</th>
                          <th>COUNTRY</th>
                          <th>EMAIL ADDRESS</th>
                          <th>CONTACT NUMBER</th>
                          <th>BRAND</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.excelData.map((row, rowKey) => (
                          <tr
                            className={
                              this.state.dataSaveError &&
                                this.state.dataSaveError?.duplicates &&
                                row["domain"] &&
                                this.state.dataSaveError?.duplicates[
                                row["domain"]
                                ]
                                ? "duplicate-error"
                                : ""
                            }
                            key={rowKey}
                          >
                            {this.state[this.state.selectedKey].map(
                              (domainKey, domainKeyIndex) => (
                                <td key={domainKeyIndex} className="form-group">
                                  {/* <input name={domainKey} onChange={(e) => this.changeInputBoxValue(e.target.value, rowKey, domainKey )} value={row[domainKey]} className="form-control" /> */}
                                  {this.renderInfrigementComponentInput(
                                    domainKey,
                                    row,
                                    rowKey
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  fileUploadingMessage() {
    if (this.state.filesUploading) {
      return (
        <>
          <p>Wait until files are uploading.</p>
        </>
      );
    }
  }

  removeDataItem(rowKey) {
    let excelData = this.state.excelData;

    if (rowKey > -1) {
      excelData.splice(rowKey, 1); // 2nd parameter means remove one item only
    }

    this.setState(() => ({
      excelData,
    }));
  }

  renderInfrigementComponentInput(domainKey, row, rowKey) {
    if (domainKey === "status" && this.state.platform !== "e-commerce") {
      return (
        <select
          value={row[domainKey]}
          className="form-control"
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
        >
          {fetchInfrigementAllStatus().map((status, index) => (
            <option
              selected={row[domainKey] === status.code ? "selected" : ""}
              key={index}
              value={status.code}
            >
              {status.text}
            </option>
          ))}
        </select>
      );
    } else if (domainKey === "status" && this.state.platform === "e-commerce") {
      return (
        <select
          value={row[domainKey]}
          className="form-control"
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
        >
          {fetchInfrigementEcommerceStatus().map((status, index) => (
            <option
              selected={row[domainKey] === status.code ? "selected" : ""}
              key={index}
              value={status.code}
            >
              {status.text}
            </option>
          ))}
        </select>
      );
    } else if (domainKey === "sn") {
      return (
        <>
          <a
            onClick={(e) => this.removeDataItem(rowKey)}
            className="text-danger"
            href="#"
          >
            <i class="fa fa-trash" aria-hidden="true"></i>
          </a>
          &nbsp;
          <span className='text-muted'>{row[domainKey]}</span>
        </>
      );
    } else if (domainKey === "image") {
      return (
        <>
          {row.imageUrl && <img width="50px" src={row.imageUrl} alt="image" />}
          <input
            id={"input" + rowKey}
            type="file"
            name={domainKey}
            onChange={(e) =>
              this.updateImageInputEvent(e.target.id, rowKey, domainKey)
            }
            className="form-control"
          />
        </>
      );
    } ////    LDOTR-10 Start    ////
    else if (domainKey.toUpperCase() === 'DATEADDED') {
      return (
        <input
          name={domainKey}
          onChange={(e) =>
            this.changeInputBoxValue(e.target.value, rowKey, domainKey)
          }
          value={this.state.reportingDate} //reporting date is DateAdded column
          className="form-control"
        />
      );
    }  ////    LDOTR-10 End    ////
    else {
      if (domainKey === 'lid') {
        return (
          <input
            name={domainKey}
            onChange={(e) =>
              this.changeInputBoxValue(e.target.value, rowKey, domainKey)
            }
            value={row[domainKey]}
            className={domainKey === 'lid' ? this.state.dataSaveError &&
              this.state.dataSaveError?.duplicates &&
              row["lid"] &&
              this.state.dataSaveError?.duplicates[row["lid"]]
              ? "form-control duplicate-errors"
              : "form-control"
              : "form-control"
            }
          />
        );
      } else if (
        [
          "PostDate",
          "LikesCount",
          "CommentsCount",
          "SharedCount",
          "Follwer/SubscriberCount",
          "LastPostDate",
          "LastPostComments",
          "LastPostLikes",
          "LastPostShare",
          "Creation/JoiningDate",
          "FollowingCount",
          "LastPostCount",
          "ViewCount",
          "PostCount/YouTube_VideoCount",
          "PostDuration_yt",
          "Telegram_LinkCount",
        ].includes(domainKey)) {
        return this.renderNewColumnSocialMedia(row, rowKey, domainKey)
      }
      else {

        return (
          <input
            name={domainKey}
            onChange={(e) => {
              this.changeInputBoxValue(e.target.value, rowKey, domainKey)
              if (domainKey === 'username' && this.state.platform === "e-commerce") {
                if (this.state.dataSaveError?.whiteLabelUrl) {
                  delete this.state.dataSaveError.duplicates[row['url']]
                }
              }
            }
            }
            value={row[domainKey]}
            className={domainKey === 'url' ? this.state.dataSaveError &&
              this.state.dataSaveError?.duplicates &&
              row["url"] &&
              this.state.dataSaveError?.duplicates[row["url"]]
              ? "form-control duplicate-errors"
              : "form-control"
              : "form-control"
            }
          />
        );
      }
    }
  }

  renderNewColumnSocialMedia(row, rowKey, domainKey) {
    return (
      <input
        name={domainKey}
        onChange={(e) =>
          this.changeInputBoxValue(e.target.value, rowKey, domainKey)
        }
        value={row[domainKey] || ""}
        className={domainKey === 'url' ? this.state.dataSaveError &&
          this.state.dataSaveError?.duplicates &&
          row["url"] &&
          this.state.dataSaveError?.duplicates[row["url"]]
          ? "form-control duplicate-errors"
          : "form-control"
          : "form-control"
        }
      />
    );
  }

  updateImageInputEvent(id, rowKey, domainKey) {
    const input = document.getElementById(id);

    if (!input) {
      return;
    }

    this.handleSingleImageUpload(input.files, rowKey, domainKey);
  }

  async handleSingleImageUpload(files, rowKey, domainKey) {
    let formData = new FormData();

    //Adding files to the formdata

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "true",
      },
    };

    await window.axios
      .post("/upload-images-to-s3", formData, options)
      .then((res) => {
        let upoadedFile = res.data.Data[0];
        let data = this.state.excelData;
        data[rowKey].image = upoadedFile.uploadedImage;
        data[rowKey].imageUrl = upoadedFile.Location;
        data[rowKey].s3BucketName = upoadedFile.Bucket;
        data[rowKey].s3FullImagePath = upoadedFile.Key;
        data[rowKey].updated = true;
        this.setState({ excelData: data });
      });
  }

  websiteComponent() {
    if (this.state.platform === "website") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              {this.listHeaderComponent("Website")}
              <div style={{ paddingTop: "20px" }} className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>IMAGE</th>
                          <th>URL</th>
                          <th>DOMAIN</th>
                          <th>DATE ADDED</th>
                          <th>PRIORITY</th>
                          <th>STATUS</th>
                          <th>BRAND</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.excelData.map((row, rowKey) => (
                          <tr
                            className={
                              this.state.dataSaveError &&
                                this.state.dataSaveError?.duplicates &&
                                row["url"] &&
                                this.state.dataSaveError?.duplicates[row["url"]]
                                ? "duplicate-error"
                                : ""
                            }
                            key={rowKey}
                          >
                            {this.state[this.state.selectedKey].map(
                              (domainKey, domainKeyIndex) => (
                                <td key={domainKeyIndex} className="form-group">
                                  {this.renderInfrigementComponentInput(
                                    domainKey,
                                    row,
                                    rowKey
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  socialMediaComponent() {
    if (this.state.platform === "social-media") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              {this.listHeaderComponent("Social Media")}

              <div style={{ paddingTop: "20px" }} className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>IMAGE</th>
                          <th>PLATFORM</th>
                          <th>HEADING</th>
                          <th>LINK</th>
                          <th>DATE ADDED</th>
                          <th>PRIORITY</th>
                          <th>STATUS</th>
                          <th>PostDate</th>
                          <th>LikesCount</th>
                          <th>CommentsCount</th>
                          <th>SharedCount</th>
                          <th>PostDuration_yt</th>
                          <th>Follower/SubscriberCount</th>
                          <th>FollowingCount</th>
                          <th>LastPostDate</th>
                          <th>LastPostComments</th>
                          <th>LastPostLikes</th>
                          <th>LastPostShare</th>
                          <th>LastPostCount</th>
                          <th>Creation/JoiningDate</th>
                          <th>PostCount/YouTube_VideoCount</th>
                          <th>ViewCount</th>
                          <th>Telegram_LinkCount</th>
                          <th>Brand</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.excelData.map((row, rowKey) => (
                          <tr
                            className={
                              this.state.dataSaveError &&
                                this.state.dataSaveError?.duplicates &&
                                row["link"] &&
                                this.state.dataSaveError?.duplicates[row["link"]]
                                ? "duplicate-error"
                                : ""
                            }
                            key={rowKey}
                          >
                            {this.state[this.state.selectedKey].map(
                              (domainKey, domainKeyIndex) => (
                                <td key={domainKeyIndex} className="form-group">
                                  {this.renderInfrigementComponentInput(
                                    domainKey,
                                    row,
                                    rowKey
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  async addNewRecord() {
    let selectedPlatform = this.state.platform;
    let selectedKey = this.state.selectedKey;

    let newRecord = {};

    this.state[selectedKey].map((index, key) => {
      newRecord[index] = "";
    });

    let currentData = this.state.excelData;
    newRecord["sn"] = currentData.length + 1;


    currentData.push(newRecord);

    await this.setState(() => ({
      excelData: currentData,
    }));
  }

  ecommerceComponent() {
    if (this.state.platform === "e-commerce") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              {this.listHeaderComponent("E-COMMERCE")}
              <div style={{ paddingTop: "20px" }} className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      {/* add the for Comparative Link and Comparative Price header*/}
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>IMAGE</th>
                          <th>SITE</th>
                          <th>DATE ADDED</th>
                          <th>URL</th>
                          <th>TITLE</th>
                          <th>PRICE</th>
                          <th>REASON</th>
                          <th>REASON CODE</th>
                          <th>COMPARATIVE LINK</th>
                          <th>COMPARATIVE PRICE</th>
                          <th>PRIORITY</th>
                          <th>STATUS</th>
                          <th>USERNAME</th>
                          <th>STORE ID</th>
                          <th>ADDRESS</th>
                          <th>CITY</th>
                          <th>COUNTRY</th>
                          <th>Rating</th>
                          <th>Number of Ratings</th>
                          <th>Number of Reviews</th>
                          <th>MRP</th>
                          <th>Seller Rating</th>
                          <th>Contact Number</th>
                          <th>PID</th>
                          <th>LID</th>
                          <th>Discount Percentage</th>
                          <th>Flipkart Assured</th>
                          <th>Brand</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.excelData.map((row, rowKey) => {
                          let isError = false
                          const url = row?.url
                          if (this.state.dataSaveError?.whiteLabelUrl >= 1) {
                            if (this.state.dataSaveError?.duplicates[url]) {
                              isError = true
                            }
                          }
                          return (
                            <tr
                              key={rowKey}
                              className={isError ? "duplicate-error" : ""}
                            >
                              {this.state[this.state.selectedKey].map(
                                (domainKey, domainKeyIndex) => (
                                  <td key={domainKeyIndex} className="form-group">
                                    {this.renderInfrigementComponentInput(
                                      domainKey,
                                      row,
                                      rowKey
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  listHeaderComponent(heading) {
    return (
      <>
        <h5 style={{ display: "inline" }} className="card-title">
          {heading}
          <div className="col-14 text-right mb-0 add-single-record-btn-wrapper  ">
            <button
              type="button"
              class="btn btn-primary add-single-record-btn"
              onClick={(e) => this.addNewRecord()}
            >
              Add a record
            </button>
          </div>
        </h5>
      </>
    );
  }

  appStoreComponent() {
    if (this.state.platform === "app-store") {
      return (
        <div className="col">
          <div className="card card-small mb-4">
            <div className="card-body">
              {this.listHeaderComponent("APP STORE")}

              <div style={{ paddingTop: "20px" }} className="row">
                <div className="col-md-12">
                  <div className="table-responsive fix-height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>IMAGE</th>
                          <th>APP NAME</th>
                          <th>APP STORE</th>
                          <th>DATE ADDED</th>
                          <th>URL</th>
                          <th>DEVELOPER NAME</th>
                          <th>LATEST PUBLISH DATE</th>
                          <th>NUMBER OF DOWNLOADS</th>
                          <th>VERSION</th>
                          <th>STATUS</th>
                          <th>PRIORITY</th>
                          <th>PRICE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.excelData.map((row, rowKey) => (
                          <tr
                            className={
                              this.state.dataSaveError &&
                                this.state.dataSaveError?.duplicates &&
                                row["url"] &&
                                this.state.dataSaveError?.duplicates[row["url"]]
                                ? "duplicate-error"
                                : ""
                            }
                            key={rowKey}
                          >
                            {this.state[this.state.selectedKey].map(
                              (domainKey, domainKeyIndex) => (
                                <td key={domainKeyIndex} className="form-group">
                                  {this.renderInfrigementComponentInput(
                                    domainKey,
                                    row,
                                    rowKey
                                  )}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  handleChange = (selectedOption) => {
    const selectedBrand = this.state.brands.find(
      (brand) => brand.id === selectedOption.value
    );

    this.setState({
      brand: selectedOption.value,
      brandId: selectedBrand ? selectedBrand.id : null,
    });
  };

  renderHtml() {
    if (!this.state.authorised) {
      return <UnauthorisedPage />;
    }

    const options = this.state.brands.map((brand, brandKey) => ({
      value: brand.id,
      label: brand.brandName,
    }));
    options.unshift({ value: undefined, label: "Select from Brand" })

    return (
      <div className="row">
        {this.props?.login?.user?.role === 'Admin' ? <AdminSideNav /> : <AnalystSideNav />}

        <main
          id="data-upload-screen"
          className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3"
        >
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-4 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Upload Data
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-small mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Company
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({ company: e.target.value })
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.companies.map(
                                (company, companyIndex) => (
                                  <option key={companyIndex} value={company.id}>
                                    {company.name}
                                  </option>
                                )
                              )}
                            </select>
                            {this.state.dataSaveError?.company && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.company}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Brand
                            </span>
                            <select
                              onChange={(e) =>
                                this.setState({ brand: e.target.value })
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.brands.map((brand, brandKey) => (
                                <option key={brandKey} value={brand.id}>
                                  {brand.brandName}
                                </option>
                              ))}
                            </select>
                            {this.state.dataSaveError?.brand && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.brand}
                              </span>
                            )}
                          </div>
                        </div> */}
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Brand
                            </span>
                            {/* <select
                              onChange={(e) =>
                                this.setState({ brand: e.target.value })
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.brands.map((brand, brandKey) => (
                                <option key={brandKey} value={brand.id}>
                                  {brand.brandName}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              options={options}
                              onChange={this.handleChange}
                              defaultValue={{ value: undefined, label: "Select from Brand" }}
                            />

                            {this.state.dataSaveError?.brand && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.brand}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Platform
                            </span>
                            {/* <select
                              onChange={(e) => this.changePlatformSelectBox(e)}
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              <option value="domain">Domain</option>
                              <option value="website">Website</option>
                              <option value="social-media">Social Media</option>
                              <option value="e-commerce">E-commerce</option>
                              <option value="app-store">App Store</option>
                            </select> */}
                            <select
                              value={this.state.platform}
                              onChange={(e) => this.changePlatformSelectBox(e)}
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.domain) ||
                                !this.state.availablePlatforms) && (
                                  <option value="domain">Domain</option>
                                )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.website) ||
                                !this.state.availablePlatforms) && (
                                  <option value="website">Website</option>
                                )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.socialMedia) ||
                                !this.state.availablePlatforms) && (
                                  <option value="social-media">
                                    Social Media
                                  </option>
                                )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.eCommerce) ||
                                !this.state.availablePlatforms) && (
                                  <option value="e-commerce">E-commerce</option>
                                )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.appStore) ||
                                !this.state.availablePlatforms) && (
                                  <option value="app-store">App Store</option>
                                )}
                            </select>
                            {this.state.dataSaveError?.platform && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.platform}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Reporting Date
                            </span>
                            <input
                              type="date"
                              id="start"
                              name="trip-start"
                              value={this.state.reportingDate}
                              onChange={(e) =>
                                this.setState({ reportingDate: e.target.value })
                              }
                              className="form-control"
                              min="2018-01-01"
                            />
                          </div>
                          {this.state.dataSaveError?.reportingDate && (
                            <span className="text-danger">
                              {this.state.dataSaveError?.reportingDate}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Upload
                            </span>
                            <input
                              id="input"
                              name="files"
                              multiple
                              type="file"
                            />
                          </div>
                          {this.state.dataSaveError?.data && (
                            <span className="text-danger">
                              {this.state.dataSaveError?.data}
                            </span>
                          )}
                        </div>
                        {this.renderUploadDataBtn()}
                        <div className="col-md-3">
                          <div
                            style={{
                              margin: 0,
                              padding: 0,
                              marginTop: 25,
                              color: "#c17900",
                            }}
                            className="form-group"
                          >
                            {this.fileUploadingMessage()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Domain Upload Screen */}
            {this.domainComponent()}
            {/* Website upload screen */}
            {this.websiteComponent()}
            {/* Social Media Screen */}
            {this.socialMediaComponent()}
            {/* E-COMMERCE */}
            {this.ecommerceComponent()}
            {/* APP STORE */}
            {this.appStoreComponent()}
          </div>
          {/* data saved sweet alert */}
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(DataUpload)))
);